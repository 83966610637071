import React,{useState,useEffect}  from "react";
import  '../components/bootstrap.min.css';
import banner from '../components/images/banner-company.jpg'
import loadable from '@loadable/component'
// const Footer = loadable(() => import('../components/Footer'))
// const Nav = loadable(() => import ('../components/Nav'))
// const TopNav = loadable(() => import ('../components/TopNav'))
import Footer  from "../components/Footer";
import Nav from "../components/Nav";
import { Link } from "gatsby";

function Company(){
return(
<>

    <Nav/>
<section className=" min-height-400px cover-background top-space-verum" style={{backgroundImage: `url(${banner})`}}>
<div className="container">
    <div className="row">
        <div className="col-12 col-lg-8 ">
            <h3 className="inner-banner-title text-white">Solve Software Engineering<br/>Complexity</h3>
        </div> 
        <div className="col-12 col-lg-4 " data-wow-delay="0.2s"></div>
    </div>
</div>
</section>
<section className="padding-70px-tb ">
<div className="container">
    <div className="row justify-content-center">
        <div className="col-12 col-lg-6">
            <h3 className="title-section alt-font text-dark-gray letter-spacing-1">About Verum</h3>


            <p> Verum is a leading developer of model-based software design tools to create, simulate, mathematically verify (discrete AI), and automatically generate code for embedded and cyber-physical systems.
            We offer solutions in areas such as hyperautomated DevOps and Interfaces & Protocols. Verum operates in various markets, including semiconductor, metrology, and mobility. We support software engineers in creating trusted software in half the time they currently require.</p>


</div>
        <div className="col-12 col-lg-6">
            <iframe id="player_1" style={{border: "0px" ,width: "100%" ,height: "300px"}} title="" src="https://www.youtube.com/embed/kA7pNkZJHho?autoplay=0&amp;enablejsapi=1&amp;wmode=opaque" name="fitvid0" allowfullscreen="allowfullscreen"></iframe>
        </div>
    </div>                               
</div>
</section>
<section className="padding-70px-tb bg-light-gray ">
<div className="container">
    <div className="row justify-content-center">
        <div className="col-12 col-lg-6">
            <h6 className=" title-section-alt alt-font">History</h6>
            <p>Verum was founded in 2004 with the belief that there was a better way to develop complex embedded software. Through the application of proven mathematical techniques, Verum has developed unique technology that is at the heart of our software design tools.</p>
        </div>
        <div className="col-12 col-lg-6">
            <h6 className="title-section-alt alt-font">References</h6>
            <p>Verum has established reference customers for its technology which include leading companies such as Philips, ASML and Thermo Fisher. Through these projects we have demonstrated that our products deliver an improvement in productivity and a corresponding decrease in time to market and software defects.</p>
            <p>Based close to Eindhoven in The Netherlands, Verum maintains close ties with researchers of the Technical University of Eindhoven (TU/e).</p>
        </div>
    </div>                               
</div>
</section>
<Footer/>
</>);
 }
export default Company;
